<template>
  <div class="videoRingtone">
    <img class="iconimg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAll/20_top.jpg" />
    <div class="form">
      <img class="one" v-show="num == 1" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif" />
      <van-field v-model="form.phone" type="tel" maxlength="11" placeholder="请输入您的手机号码" @input="changePhone" />
      <img class="two" v-show="num == 2" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_04.gif" />
      <img class="three" v-show="num == 3" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_05.gif" />
      <template>
        <van-field v-model="form.code" type="number" center maxlength="6" autocomplete="one-time-code"
          placeholder="请输入短信验证码" :class="['input-code', { 'two-cell': num == 2 }]">
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>
      <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/YDLiaoNing/videoRingtone26_submit_lijidinggou.png"
        class="submit" @click="onSubmit" />
      <img class="four" v-show="num == 4" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_06.gif" />
      <div class="tag">
        20元10GB全国流量5G加油包，资费20元/月
      </div>
      <div class="tag">
        注:订购立即生效，次月自动续订
      </div>
    </div>
    <img class="iconimg qdbm" src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAll/20_info2.png" />
    <v-confirm closeBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAll/30_close.png"
      submitBtnImg="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAll/30_submit.png" :isClose="false" :width="722"
      :height="709" background="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAll/20_dialog.png"
      :visibility.sync="visibility" />
    <div class="bottomCodeAside" @click="visibilityQDBS = true">授权信息</div>
    <base-dialog-img :visibility.sync="visibilityQDBS" />
  </div>
</template>

<script>
import { tradeOrder, getVerificationCode } from "@/api/qiuMoFusion/index.js";
import VConfirm from "@/components/BaseDialog/confirm.vue";
import BaseDialogImg from "@/components/BaseDialog/img.vue";
import { JuDuoDuo } from "@/utils/common.js";

export default {
  components: {
    VConfirm,
    BaseDialogImg,
  },
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "714208161783",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      visibility: false,
      visibilityQDBS: false,
    };
  },
  created() {
    // document.title = "20元10G全国流量5G加油包";
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    this.form.characteristic = from + reasonCode;
    this.form.marketUrl = encodeURIComponent(window.location.href);
    // const stroagePhone = localStorage.getItem("phone");
    this.form.phone = phone || "";
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  mounted() {
    const that = this;
    const phone = that.form.phone || "";
    window.onpopstate = function (event) {
      // 返回拦截
      JuDuoDuo("100023", phone, "20元10G全国流量5G加油包");
      // liuliangguo("103696", phone, "20元10G全国流量5G加油包");
    };
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changePhone(value) {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (filterPhone.test(value)) {
        localStorage.setItem("phone", value);
      }
    },
    // 验证码
    getAuthCode() {
      if (this.yzm_loading) return;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$toast({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$toast({ message: "请填写正确的手机号" });
      } else {
        this.yzm_loading = true;
        try {
          getVerificationCode(JSON.stringify(this.form))
            .then((res) => {
              this.yzm_loading = false;
              if (res.code != 0) {
                this.$dialog.alert({ message: res.message || '验证码获取失败' });
                // liuliangguo(
                //   "103697",
                //   this.form.phone,
                //   "20元10G全国流量5G加油包"
                // );
                JuDuoDuo("100023", this.form.phone, "20元10G全国流量5G加油包");
              } else {
                this.form.data = res.data.data.data;
                this.form.orderId = res.data.data.orderId;
                // 设置倒计时秒
                this.sendAuthCode = false;
                this.auth_time = 120;
                let auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              }
            })
            .catch((error) => {
              this.yzm_loading = false;
            });
        } finally {
          this.yzm_loading = false;
        }
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.close();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || '提交失败',
          });
          JuDuoDuo("100023", this.form.phone, "20元10G全国流量5G加油包");
          // liuliangguo("103695", this.form.phone, "20元10G全国流量5G加油包");
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "left",
          });
          JuDuoDuo("100023", this.form.phone, "20元10G全国流量5G加油包");
          // liuliangguo("103694", this.form.phone, "20元10G全国流量5G加油包");
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$toast({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$toast({ message: "请填写正确的手机号" });
      } else if (!this.form.code) {
        this.$toast({ message: "请输入验证码" });
      } else if (!filtercode.test(this.form.code)) {
        this.$toast({ message: "请输入6位验证码" });
      } else {
        this.visibility = true;
      }
    },
    close() {
      this.$toast.clear();
      this.visibility = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.videoRingtone {
  background-color: #e33c50;

  .bottomCodeAside {
    font-size: 30px;
    background-color: #b84e00;
    color: #fff;
    position: fixed;
    right: -10px;
    bottom: 40px;
    writing-mode: vertical-lr;
    padding: 10px;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    font-weight: 500;
  }

  ::v-deep .footer {
    padding-top: 590px;
    align-items: center;
    justify-content: center;

    .btn-close {
      width: 215px;
      height: 72px;
      padding: 0 20px 0 12px !important;
    }

    .btn-submit {
      width: 215px;
      height: 72px;
      padding-left: 20px;
    }
  }

  .iconimg {
    width: 100%;
    pointer-events: none;

    &.qdbm {
      padding: 0 30px;
      box-sizing: border-box;
    }
  }

  .form {
    position: relative;
    // background: url(https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTShanXi/woChangYou25/form_bg.png)
    //   no-repeat;

    background-size: 100% 100%;
    padding-top: 90px;
    padding-bottom: 40px;

    .one,
    .two,
    .three,
    .four {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }

    .one {
      transform: translate(72px, -90px);
    }

    .two {
      width: 152px;
      right: 40px;
      top: 50px;
      transform: translate(-2px, 90px);
    }

    .three {
      width: 430px;
      transform: translate(80px, -20px);
    }

    .four {
      width: 332px;
      transform: translate(200px, -90px);
    }

    ::v-deep .van-cell {
      width: 680px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0 auto;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 40px;
      border: 1px solid #999;

      .el-loading-mask {
        background-color: transparent;
      }

      &.input-code {
        margin-top: 45px;

        .code {
          width: 229px;
          text-align: center;
          line-height: 100px;
          color: #ffffff;
          font-size: 30px;
          font-weight: normal;
          border-left: solid 3px #b2b2b2;
          background-color: #ff840d;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 40;
          color: #969696;
        }
      }
    }

    .submit {
      width: 660px;
      display: block;
      margin: 20px auto 0;
      animation: scaleDraw 2s infinite;

      @keyframes scaleDraw {
        0% {
          transform: scale(1);
        }

        25% {
          transform: scale(0.9);
        }

        50% {
          transform: scale(1);
        }

        75% {
          transform: scale(0.9);
        }
      }

      &.none {
        pointer-events: none;
      }

      &.bottom {
        position: fixed;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 634px;
        bottom: 70px;
      }
    }

    .tag {
      color: #fff;
      font-size: 24px;
      font-weight: 5000;
      text-align: center;
    }
  }
}
</style>