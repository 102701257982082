<template>
  <van-popup v-model="visibility" class="dialog-img" :close-on-click-overlay="false">
    <span class="close" @click="close">×</span>
    <img class="img" :src="background" />
  </van-popup>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTAll/qdbm.jpg",
    },
    isClose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$parent.handleSubmit();
    },
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-img {
  box-sizing: border-box;

  .close {
    position: absolute;
    right: 20px;
    font-weight: 500;
    color: #bbb;
    font-size: 50px;
  }

  .img {
    width: 600px;
  }
}
</style>
